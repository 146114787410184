/* Fuentes Toyota */
@font-face {font-family: Titulo; src: url('./assets/fonts/ToyotaType-Semibold.ttf');}
@font-face {font-family: Contenido; src: url('./assets/fonts/ToyotaType-Book.ttf');}

.slick-list {
    height: auto !important;
}
.slick-track {
    height: auto !important;
}
.slick-slide {
    height: auto !important;
}
.ant-pagination-item-active a {
    background: #000000;
    color: #ffffff;
    border: 1px solid #000000;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
    color: red;
    font-weight: bold;
    border-top: 5px solid;
    padding-top: 17px;
}
.ant-menu-horizontal{
    border-bottom: none;
}